import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  Botton_action,
  modal_position,
  modal_size,
  pageSizeOptions,
  titulos,
  modules,
} from "app/@components/confi/confi";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MatSort, Sort } from "@angular/material/sort";
import * as generalActions from "app/@components/general/ngrx/general.actions";
import * as indexActions from "app/@components/general/ngrx/index/index.actions";
import { PageEvent } from "@angular/material/paginator";
import { ClienteProveedorPopupAddUpdComponent } from "./popupAddUpd/ClienteProveedorPopupAddUpd.component";
import { ClienteProveedorDetailPopupComponent } from "./detailPopup/ClienteProveedorDetailPopup.component";
import { CrudDeletePopupComponent } from "app/@components/general/componentes-crud/crud-delete-popup/crud-delete-popup.component";
import { UtilService } from "app/@components/services/util.service";
import { Empresa } from "app/@components/models/Empresa";
import { RespuestaService } from "app/@components/respuesta.service";
import { ShowToastService } from "app/@components/showToast.service";
import { state } from "@angular/animations";
@Component({
  selector: "ngx-clienteProveedor",
  templateUrl: "./clienteProveedor.component.html",
  styleUrls: ["./clienteProveedor.component.scss"],
})
export class ClienteProveedorComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  items: any[];
  itemsSub: Subscription = new Subscription();
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  length = undefined;
  pageSize = undefined;
  pageIndex = undefined;
  pageSizeOptions = pageSizeOptions;
  pageEvent: PageEvent;
  indexSubscription: Subscription = new Subscription();
  actionCreateSubscription: Subscription = new Subscription();
  actionLecturaSubscription: Subscription = new Subscription();
  Botton_action = Botton_action;
  lectura: any = undefined;
  modules = modules;
  path: any;
  empresa: Empresa;
  constructor(
    private store: Store<any>,
    public dialog: MatDialog,
    public utilService: UtilService,
    private respuestaService: RespuestaService,
    private showToastService: ShowToastService
  ) {}

  ngOnInit() {
    this.lectura = this.utilService.validateReading(
      this.modules.clienteProveedor.path
    );
    //console.log(this.lectura);
    this.itemsSub = this.store
      .select((state) => {
        //  console.log("Estado completo:", state);
        // console.log("general:", state?.general);
        //console.log("index:", state?.general?.index);
        //console.log("items:", state?.general?.index?.items);
        return state?.general?.index?.items;
      })
      .subscribe((items) => {
        //console.log("Items seleccionados:", items);

        if (items != null) {
          this.items = items;
          //console.log("this.items:", this.items);
          this.dataSource.data = this.items;
          this.dataSource.sort = this.sort;
          this.selection = new SelectionModel<any>(true, []);
        }
      });

    this.indexSubscription = this.store
      .select((state) => state?.general?.index)
      .subscribe((index) => {
        if (index != null && index != undefined && index?.count) {
          this.length = index.count;
          this.pageSize = index.quantity;
          this.pageIndex = index.page;
        }
      });
    this.actionCreateSubscription = this.store
      .select((state) => state?.general?.modulo?.actionCreate)
      .subscribe((actionCreate) => {
        if (actionCreate) {
          if (this.empresa) {
            this.insertEditDataPopup(0);
          } else {
            let respuesta = this.respuestaService.itemEmpresa();
            this.showToastService.showToast(
              "info",
              respuesta.message,
              respuesta.detalle
            );
          }
        }
      });

    this.displayedColumns = [
      "fP01RazonSocial",
      "fP01Ruc",
      "fP01Direccion",
      "fP01Telefono",
      "Acciones",
    ];
    this.store.dispatch(
      generalActions.SetTititleCrud({
        title: modules.clienteProveedor.title,
        name: modules.clienteProveedor.name,
      })
    );
    //   this.store.dispatch(indexActions.GetItemsAction());
    //obtiene datos de la empresa de la empresa
    this.itemsSub = this.store
      .select((state) => state?.general?.index?.itemEmpresa)
      .subscribe((itemEmpresa) => {
        if (itemEmpresa != null) {
          this.empresa = itemEmpresa;
          //console.log(this.empresa);
        }
      });
  }
  ngOnDestroy(): void {
    this.store.dispatch(generalActions.ClearStorageAction());
    this.itemsSub.unsubscribe();
    this.indexSubscription.unsubscribe();
    this.actionCreateSubscription.unsubscribe();
    this.actionLecturaSubscription.unsubscribe();
  }
  insertEditDataPopup(id: any) {
    let title =
      id == 0 ? Botton_action.Crear.nombre : Botton_action.Editar.nombre;
    this.dialog.open(ClienteProveedorPopupAddUpdComponent, {
      width: modal_size.LG,
      position: { top: modal_position.top },
      disableClose: true,
      data: { title: title, id: id, empresa: this.empresa },
    });
  }

  sortData(sort: Sort) {
    if (sort.active && sort.direction) {
      this.store.dispatch(
        indexActions.ChangeOrderByAndOrderTypeAction({
          orderBy: sort.active,
          orderType: sort.direction,
        })
      );
    } else {
      this.store.dispatch(
        indexActions.ChangeOrderByAndOrderTypeAction({
          orderBy: undefined,
          orderType: undefined,
        })
      );
    }
  }
  changePagination($event) {
    this.pageEvent = $event;
    this.store.dispatch(
      indexActions.NumberPageQuantityAction({
        page: this.pageEvent.pageIndex,
        quantity: this.pageEvent.pageSize,
      })
    );
  }

  detailPopUp(id: number) {
    this.dialog.open(ClienteProveedorDetailPopupComponent, {
      width: modal_size.MD,
      position: { top: modal_position.top },
      disableClose: true,
      data: { title: titulos.detallePopop, id: id },
    });
  }
  deletePopUp(data: any) {
    let items: any[] = [];
    let item = {
      id: data?.fP01Id,
      nombre: data?.fP01RazonSocial,
    };
    items.push(item);
    this.dialog.open(CrudDeletePopupComponent, {
      width: modal_size.MD,
      position: { top: modal_position.top },
      disableClose: true,
      data: {
        title: titulos.eliminarPopop + " " + modules.clienteProveedor.title,
        module: modules.clienteProveedor.name,
        data: items,
      },
    });
  }
}
