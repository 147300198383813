import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as generalActions from "app/@components/general/ngrx/general.actions";
import { Empresa } from "app/@components/models/Empresa";
import * as uuid from "uuid";
import { modules } from "app/@components/confi/confi";
import * as indexActions from "app/@components/general/ngrx/index/index.actions";
import { Validators } from "@angular/forms";
import { RegistroAsignacionProyectoComponent } from "app/view/consultor/registroAsignacionProyecto/registroAsignacionProyecto.component";
import { th } from "date-fns/locale";
@Component({
  selector: "ngx-crud-escifica-empresa",
  templateUrl: "./crud-escifica-empresa.component.html",
  styleUrls: ["./crud-escifica-empresa.component.scss"],
})
export class CrudEspecificaEmpresaComponent implements OnInit, OnDestroy {
  empresas: Empresa[] = [];

  selectedEmpresa: Empresa | undefined;
  dataFormSub: Subscription = new Subscription();
  popupId = uuid.v4();
  modules = modules;
  itemForm: any;
  fb: any;
  maxDate: string;
  minDate: string;

  constructor(
    private store: Store<any>,
    private buxas: RegistroAsignacionProyectoComponent
  ) {
    const today = new Date();
    const year = today.getFullYear();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const day = ("0" + today.getDate()).slice(-2);

    this.maxDate = `${year}-${month}-${day}`;
    this.minDate = `${year}-${month}-${day}`;

    this.fechaMenos8();
  }

  fechaMenos8() {
    const today = new Date(); // Fecha actual
    today.setDate(today.getDate() - 8); // Restar 8 días

    // Obtener año, mes y día con formato correcto
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Meses van de 0 a 11, ajustamos y rellenamos con '0'
    const day = String(today.getDate()).padStart(2, "0"); // Día con dos dígitos

    // Formatear la fecha
    this.minDate = `${year}-${month}-${day}`;
    //console.log(this.minDate); // Muestra la fecha calculada
  }

  ngOnInit(): void {
    this.store.dispatch(
      generalActions.GetDataFormAction({
        id: undefined,
        module: this.modules.empresa.name,
        popupId: this.popupId,
        actionResult: "GetData",
      })
    );
    this.dataFormSub = this.store
      .select((state) => state?.general?.item[this.popupId]?.dataForm)
      .subscribe((dataForm) => {
        if (dataForm) {
          this.empresas = dataForm?.empresas;
        }

        if (this.empresas.length > 0) {
          this.selectedEmpresa = this.empresas[0];
          this.search(this.selectedEmpresa);
        }
      });

    if (this.empresas.length > 0) {
      this.selectedEmpresa = this.empresas[0];

      this.search(this.selectedEmpresa);
    }
  }
  ngOnDestroy(): void {
    this.dataFormSub.unsubscribe();
    this.store.dispatch(generalActions.ClearStorageAction());
  }

  search(item: any) {
    let data = { fS07Id: item.fS07Id };
    this.store.dispatch(
      indexActions.GetParametersReportschAction({ parameters: data })
    );
    this.store.dispatch(indexActions.GetItemsAction());
    this.store.dispatch(indexActions.SetIdEmpresaAction({ itemEmpresa: item }));
  }

  fechaInicio: Date;
  fechaFinal: Date;
  onInputChange(value: string) {
    const fechaComoString = value;
    const fechaComoDate = new Date(fechaComoString);
    this.fechaInicio = fechaComoDate;
  }

  onInputChangeFinal(value: string) {
    const fechaComoString = value;
    const fechaComoDate = new Date(fechaComoString);
    this.fechaFinal = fechaComoDate;
  }

  buscar() {
    this.buxas.buscar(this.fechaInicio, this.fechaFinal);
  }
}
