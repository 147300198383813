import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Botton_action, titulos } from "app/@components/confi/confi";
import * as generalActions from "../../ngrx/general.actions";
import * as uuid from "uuid";
import { Subscription } from "rxjs";
import { HttpService } from "../../services/http.service";
@Component({
  selector: "ngx-crud-delete-popup",
  templateUrl: "crud-delete-popup.component.html",
})
export class CrudDeletePopupComponent implements OnInit, OnDestroy {
  public itemForm: FormGroup;
  Botton_action = Botton_action;
  titulos = titulos;
  popupId = uuid.v4();
  uuidResponseSub: Subscription = new Subscription();
  items: any[] = [];
  items2: any[] = [];

  id1: any;
  id2: any;
  fecha: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CrudDeletePopupComponent>,
    private store: Store<any>,
    private http: HttpService
  ) {}
  ngOnInit() {
    this.id1 = this.data.fS07Id;
    this.id2 = this.data.aS02Id;
    this.fecha = this.data.data.fecha;

    this.items.push(this.data.data);

    this.data.data.forEach((element) => {
      let data: any;
      if (element.id1 > 0) {
        data = { id: element.id, id1: element.id1 };
      } else if (element.idDelete != null && element.idDelete != "") {
        data = { id: element.id, id1: element.idDelete };
      } else {
        data = element.id;
      }
      this.items2.push(data);
    });

    //close de popup
    this.uuidResponseSub = this.store
      .select((state) => state?.general?.item[this.popupId]?.uuid)
      .subscribe((uuid) => {
        if (uuid) {
          this.close();
        }
      });
  }
  ngOnDestroy() {
    this.uuidResponseSub.unsubscribe();
    // this.store.dispatch(indexActions.ClearStorageAction({ popupId: this.popupId }));
  }
  submit() {
    if (
      this.id2 == undefined &&
      this.id1 == undefined &&
      this.fecha == undefined
    ) {
      if (this.data?.returnData) {
        this.dialogRef.close(this.items);
      } else {
        this.store.dispatch(
          generalActions.DeleteItemsAction({
            module: this.data.module,
            actionResult: undefined,
            ids: this.items2,
            popupId: this.popupId,
            tipoMensaje: "3",
          })
        );
      }
    } else {
      this.http.deleteDia(this.id1, this.id2, this.fecha).subscribe(
        (response) => {
          //console.log("exito", response);
          this.close();
          location.reload();
        },
        (error) => {
          location.reload();
          // Manejar errores aquí...
        }
      );
    }
  }

  /* submit() {
  
  }
*/
  close(): void {
    this.dialogRef.close();
  }
}
